@import url("../../styles/colors.css");
@import url("../../styles/fonts.css");


.subtitle-background{
  background-position: center;
  background-size: cover;

}

.center-absolute{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.line-breaks{
  white-space: pre
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {
  .vh-subtitle{
    height: 70vh;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .vh-subtitle{
    height: 50vh;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .vh-subtitle{
    height: 45vh;
  }
}
