@import url("../../styles/colors.css");
@import url("../../styles/fonts.css");

.center-absolute{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vh-title{
  height: 50vh;
}


.title-background{
  background-position: center;
  background-size: cover;
}



/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .vh-title{
    height: 30vh;
  }
}
