:root {
  --macroalgas-primary: #719D06;
  --macroalgas-primary-light: #83A50D;
  --macroalgas-primary-lighter: #95B309;
  --macroalgas-primary-dark: #54780A;
  --macroalgas-primary-darker: #3B5A13;
  --macroalgas-secondary: #E1E2E1;
  --macroalgas-secondary-light: #F5F5F6;
  --macroalgas-black: #000000;
  --macroalgas-white: #FFFFFF;
}

/* Text color */
.primary-tx{
  color:var(--macroalgas-primary)
}
.primary-light-tx{
  color:var(--macroalgas-primary-light)
}
.primary-lighter-tx{
  color:var(--macroalgas-primary-lighter)
}
.primary-dark-tx{
  color:var(--macroalgas-primary-dark)
}
.primary-darker-tx{
  color:var(--macroalgas-primary-darker)
}
.secondary-tx{
  color:var(--macroalgas-secondary)
}
.secondary-light-tx{
  color:var(--macroalgas-secondary-light)
}
.black-tx{
  color:var(--macroalgas-black)
}
.white-tx{
  color:var(--macroalgas-white)
}

/* Background color */
.primary-bg{
  background-color:var(--macroalgas-primary)
}
.primary-light-bg{
  background-color:var(--macroalgas-primary-light)
}
.primary-lighter-bg{
  background-color:var(--macroalgas-primary-lighter)
}
.primary-dark-bg{
  background-color:var(--macroalgas-primary-dark)
}
.primary-darker-bg{
  background-color:var(--macroalgas-primary-darker)
}
.secondary-bg{
  background-color:var(--macroalgas-secondary)
}
.secondary-light-bg{
  background-color:var(--macroalgas-secondary-light)
}
.black-bg{
  background-color:var(--macroalgas-black)
}
.white-bg{
  background-color:var(--macroalgas-white)
}
