@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');



.navbar-font{
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 0.95rem;
}

.banner-font{
  font-family: 'Lato', sans-serif;
  font-weight: 900;
}

.btn-font{
  font-family: 'Lato', sans-serif;
  font-weight: 500;
}

.title-font{
  font-family: 'Lato', sans-serif;
  font-weight: 600;
}

.paragraph-font{
  font-family: 'Lato', sans-serif;
  font-weight: 500;
}

.details-title-font{
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 1.3rem;
}

.details-message-font{
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: 1rem;
}

.footer-font{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.photo-font{
  font-family: 'Lato', sans-serif;
  font-weight: 900;
}

.side-menu-font{
  font-family: 'Lato', sans-serif;
  font-weight: 600;
}

.contact-font{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 0.95rem;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {
  .banner-font{
    font-size: 2.5rem;
  }
  .btn-font{
    font-size: 0.9rem;
  }
  .title-font{
    font-size: 1.3rem;
  }
  .paragraph-font{
    font-size: 1rem;
  }
  .footer-font{
    font-size: 0.85rem;
  }
  .photo-font{
    font-size: 2rem;
  }
  .side-menu-font{
    font-size: 1.3rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .banner-font{
    font-size: 2.5rem;
  }
  .btn-font{
    font-size: 0.9rem;
  }
  .title-font{
    font-size: 1.3rem;
  }
  .paragraph-font{
    font-size: 1rem;
  }
  .footer-font{
    font-size: 0.85rem;
  }
  .photo-font{
    font-size: 2rem;
  }
  .side-menu-font{
    font-size: 1.3rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .banner-font{
    font-size: 3rem;
  }
  .btn-font{
    font-size: 1.0rem;
  }
  .title-font{
    font-size: 1.3rem;
  }
  .paragraph-font{
    font-size: 0.9rem;
  }
  .footer-font{
    font-size: 0.85rem;
  }
  .photo-font{
    font-size: 2.5rem;
  }
  .side-menu-font{
    font-size: 1.5rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .banner-font{
    font-size: 3.5rem;
  }
  .btn-font{
    font-size: 1.1rem;
  }
  .title-font{
    font-size: 1.5rem;
  }
  .paragraph-font{
    font-size: 1rem;
  }
  .footer-font{
    font-size: 0.9rem;
  }
  .photo-font{
    font-size: 3rem;
  }
  .side-menu-font{
    font-size: 2rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .banner-font{
    font-size: 3.5rem;
  }
  .btn-font{
    font-size: 1.1rem;
  }
  .title-font{
    font-size: 1.8rem;
  }
  .paragraph-font{
    font-size: 1.1rem;
  }
  .footer-font{
    font-size: 0.9rem;
  }
  .photo-font{
    font-size: 3rem;
  }
  .side-menu-font{
    font-size: 2rem;
  }
}
