@import url("../../styles/colors.css");
@import url("../../styles/fonts.css");

.side-menu{
  height: 100%;
  box-shadow: 2px 0 5px rgba(0,0,0,0.5);
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  z-index: 3;
  display:flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 0.5s ease;
}


.side-menu.open{
  transform: translateX(0)
}

.side-menu ul{
  list-style: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0px;
}

.side-menu li{
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.white-tx:hover{
  color: var(--macroalgas-secondary-light)
}

.icon-size{
  height: 30px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 20px;
}

.logo{
  height: 100px;
  margin-bottom: 50px;
}

.out-button{
  position: absolute;
  top:20px;
  right: 20px;
  background: transparent;
  border: none;
}
