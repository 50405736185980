@import url("../../styles/colors.css");
@import url("../../styles/fonts.css");

.icons-size{
  height: 25px;
}

.absolute-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav-text-color{
  color: var(--macroalgas-primary-dark)
}

.nav-background-transparent{
  background-color: rgba(245, 245, 246, 0.86);
}

.toggler{
  border: none;
  outline: none;
  background: transparent;
}

.z-index-1{
  z-index: 1
}
.z-index-2{
  z-index: 2
}

.nav-link{
  cursor: pointer;
}

.nav-link:hover{
  color: var(--macroalgas-primary-darker);
}

.cursor-pointer{
  cursor: pointer;

}
