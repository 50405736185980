@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
:root {
  --macroalgas-primary: #719D06;
  --macroalgas-primary-light: #83A50D;
  --macroalgas-primary-lighter: #95B309;
  --macroalgas-primary-dark: #54780A;
  --macroalgas-primary-darker: #3B5A13;
  --macroalgas-secondary: #E1E2E1;
  --macroalgas-secondary-light: #F5F5F6;
  --macroalgas-black: #000000;
  --macroalgas-white: #FFFFFF;
}

/* Text color */
.primary-tx{
  color:#719D06;
  color:var(--macroalgas-primary)
}
.primary-light-tx{
  color:#83A50D;
  color:var(--macroalgas-primary-light)
}
.primary-lighter-tx{
  color:#95B309;
  color:var(--macroalgas-primary-lighter)
}
.primary-dark-tx{
  color:#54780A;
  color:var(--macroalgas-primary-dark)
}
.primary-darker-tx{
  color:#3B5A13;
  color:var(--macroalgas-primary-darker)
}
.secondary-tx{
  color:#E1E2E1;
  color:var(--macroalgas-secondary)
}
.secondary-light-tx{
  color:#F5F5F6;
  color:var(--macroalgas-secondary-light)
}
.black-tx{
  color:#000000;
  color:var(--macroalgas-black)
}
.white-tx{
  color:#FFFFFF;
  color:var(--macroalgas-white)
}

/* Background color */
.primary-bg{
  background-color:#719D06;
  background-color:var(--macroalgas-primary)
}
.primary-light-bg{
  background-color:#83A50D;
  background-color:var(--macroalgas-primary-light)
}
.primary-lighter-bg{
  background-color:#95B309;
  background-color:var(--macroalgas-primary-lighter)
}
.primary-dark-bg{
  background-color:#54780A;
  background-color:var(--macroalgas-primary-dark)
}
.primary-darker-bg{
  background-color:#3B5A13;
  background-color:var(--macroalgas-primary-darker)
}
.secondary-bg{
  background-color:#E1E2E1;
  background-color:var(--macroalgas-secondary)
}
.secondary-light-bg{
  background-color:#F5F5F6;
  background-color:var(--macroalgas-secondary-light)
}
.black-bg{
  background-color:#000000;
  background-color:var(--macroalgas-black)
}
.white-bg{
  background-color:#FFFFFF;
  background-color:var(--macroalgas-white)
}

.navbar-font{
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 0.95rem;
}

.banner-font{
  font-family: 'Lato', sans-serif;
  font-weight: 900;
}

.btn-font{
  font-family: 'Lato', sans-serif;
  font-weight: 500;
}

.title-font{
  font-family: 'Lato', sans-serif;
  font-weight: 600;
}

.paragraph-font{
  font-family: 'Lato', sans-serif;
  font-weight: 500;
}

.details-title-font{
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 1.3rem;
}

.details-message-font{
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: 1rem;
}

.footer-font{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.photo-font{
  font-family: 'Lato', sans-serif;
  font-weight: 900;
}

.side-menu-font{
  font-family: 'Lato', sans-serif;
  font-weight: 600;
}

.contact-font{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 0.95rem;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {
  .banner-font{
    font-size: 2.5rem;
  }
  .btn-font{
    font-size: 0.9rem;
  }
  .title-font{
    font-size: 1.3rem;
  }
  .paragraph-font{
    font-size: 1rem;
  }
  .footer-font{
    font-size: 0.85rem;
  }
  .photo-font{
    font-size: 2rem;
  }
  .side-menu-font{
    font-size: 1.3rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .banner-font{
    font-size: 2.5rem;
  }
  .btn-font{
    font-size: 0.9rem;
  }
  .title-font{
    font-size: 1.3rem;
  }
  .paragraph-font{
    font-size: 1rem;
  }
  .footer-font{
    font-size: 0.85rem;
  }
  .photo-font{
    font-size: 2rem;
  }
  .side-menu-font{
    font-size: 1.3rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .banner-font{
    font-size: 3rem;
  }
  .btn-font{
    font-size: 1.0rem;
  }
  .title-font{
    font-size: 1.3rem;
  }
  .paragraph-font{
    font-size: 0.9rem;
  }
  .footer-font{
    font-size: 0.85rem;
  }
  .photo-font{
    font-size: 2.5rem;
  }
  .side-menu-font{
    font-size: 1.5rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .banner-font{
    font-size: 3.5rem;
  }
  .btn-font{
    font-size: 1.1rem;
  }
  .title-font{
    font-size: 1.5rem;
  }
  .paragraph-font{
    font-size: 1rem;
  }
  .footer-font{
    font-size: 0.9rem;
  }
  .photo-font{
    font-size: 3rem;
  }
  .side-menu-font{
    font-size: 2rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .banner-font{
    font-size: 3.5rem;
  }
  .btn-font{
    font-size: 1.1rem;
  }
  .title-font{
    font-size: 1.8rem;
  }
  .paragraph-font{
    font-size: 1.1rem;
  }
  .footer-font{
    font-size: 0.9rem;
  }
  .photo-font{
    font-size: 3rem;
  }
  .side-menu-font{
    font-size: 2rem;
  }
}

.bg-banner{
  background-position: center;
  background-size: cover;
}

.btn-macroalgas{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
}

.btn-macroalgas.primary-light-bg:hover{
  background-color: var(--macroalgas-primary)
}

.details-icon{
  height: 80px;
}

.details-font-color.white-bg{
  color: var(--macroalgas-black)
}

.details-font-color.primary-light-bg{
  color: var(--macroalgas-white)
}



.logo_footer{
  height: 70px;
}



.icons-size{
  height: 25px;
}

.absolute-center{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.nav-text-color{
  color: var(--macroalgas-primary-dark)
}

.nav-background-transparent{
  background-color: rgba(245, 245, 246, 0.86);
}

.toggler{
  border: none;
  outline: none;
  background: transparent;
}

.z-index-1{
  z-index: 1
}
.z-index-2{
  z-index: 2
}

.nav-link{
  cursor: pointer;
}

.nav-link:hover{
  color: var(--macroalgas-primary-darker);
}

.cursor-pointer{
  cursor: pointer;

}



.side-menu{
  height: 100%;
  box-shadow: 2px 0 5px rgba(0,0,0,0.5);
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  z-index: 3;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}


.side-menu.open{
  -webkit-transform: translateX(0);
          transform: translateX(0)
}

.side-menu ul{
  list-style: none;
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 0px;
}

.side-menu li{
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.white-tx:hover{
  color: var(--macroalgas-secondary-light)
}

.icon-size{
  height: 30px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 20px;
}

.logo{
  height: 100px;
  margin-bottom: 50px;
}

.out-button{
  position: absolute;
  top:20px;
  right: 20px;
  background: transparent;
  border: none;
}

.center-absolute{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.vh-title{
  height: 50vh;
}


.title-background{
  background-position: center;
  background-size: cover;
}



/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .vh-title{
    height: 30vh;
  }
}

.subtitle-background{
  background-position: center;
  background-size: cover;

}

.center-absolute{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.line-breaks{
  white-space: pre
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {
  .vh-subtitle{
    height: 70vh;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .vh-subtitle{
    height: 50vh;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .vh-subtitle{
    height: 45vh;
  }
}

.banner-bg{
  background-position: center;
  background-size: cover;
}

tr {
  border-top: 1px solid #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  text-align: left;

}

td{
  width: 50%;
  padding-top: 5px;
  padding-bottom: 5px;
}


.specifications-background{
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

}

.contact-form{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}


.btn-macroalgas{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  outline: none;
}

.btn-macroalgas.primary-light-bg:hover{
  background-color: var(--macroalgas-primary);
}


.contact-map{
  height: 100%;
  width: 100%;
  border: none;
}

.contact-background{
  background-size: cover;
}

.border-contact{
  border: 1px solid var(--macroalgas-primary);
  outline: none;
}

.border-contact:focus{
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px  var(--macroalgas-primary);
}

textarea{
  resize: none !important
}

.oops_container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

