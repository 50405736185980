@import url("../../styles/colors.css");
@import url("../../styles/fonts.css");


.details-icon{
  height: 80px;
}

.details-font-color.white-bg{
  color: var(--macroalgas-black)
}

.details-font-color.primary-light-bg{
  color: var(--macroalgas-white)
}
