@import url("../../styles/colors.css");
@import url("../../styles/fonts.css");

.contact-form{
  display: flex;
  flex-direction: column;
}


.btn-macroalgas{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  outline: none;
}

.btn-macroalgas.primary-light-bg:hover{
  background-color: var(--macroalgas-primary);
}


.contact-map{
  height: 100%;
  width: 100%;
  border: none;
}

.contact-background{
  background-size: cover;
}

.border-contact{
  border: 1px solid var(--macroalgas-primary);
  outline: none;
}

.border-contact:focus{
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px  var(--macroalgas-primary);
}

textarea{
  resize: none !important
}
