@import url("../../styles/colors.css");
@import url("../../styles/fonts.css");

tr {
  border-top: 1px solid #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  text-align: left;

}

td{
  width: 50%;
  padding-top: 5px;
  padding-bottom: 5px;
}


.specifications-background{
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

}
